@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&family=Roboto:wght@500&display=swap');

a{
  text-decoration: none;
  color: '#424242'  
}

.form-control{
  font-family: 'Open Sans';
}

html, body{
  margin: 0;
  padding: 0;
  font-family: 'Roboto';
}

h2{
  text-transform: uppercase;
  letter-spacing: .1rem;
  text-align: center;
  font-size: 1.5rem;
  color: #424242;
}

.slogan-home {
  width: 100%;
  animation: typing 3s;
  white-space: nowrap;
  overflow: hidden;
}

#list-ours-client::-webkit-scrollbar {
  display: none;
}

@keyframes typing {
  from {
    width: 0
  }
}

/*NAVBAR*/
nav > a, nav > span{
  text-decoration: none;
  padding: 0 .5rem;
  font-family: 'Roboto';
}

#burger-icon {
  display: none;
}

.dropdown {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFF;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 10px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.slogan-home {
  margin-top: 20%;
  color: #FFF;
  font-size: 4rem;
  font-family: 'Roboto';
  text-align: center;
  text-shadow: 2px 4px 4px #424242;
}

img.logo{
  height: 70px;
  width: auto;
  margin-top: 10px;
}

/*RESPONSIVIDADE NAVBAR*/
@media screen and (max-width: 600px) {
  nav {
    border-bottom: 1px solid #424242;
  }
  nav a, nav span {
    display: none;
  }
  #burger-icon {
    float: right;
    display: block;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 60px;
  }
  nav.responsive {
    position: relative;
    width: 100%;
  }
  nav.responsive a, nav.responsive span {
    text-align: center;
    display: block;
    padding: 10px 5px;
    font-size: 17px;
  }
  .dropdown {
    display: block;
  }
  .dropdown-content {
    width: 100%;
  }
  #top-bar-icons, .top-bar-contacts{
    display: none;
  }

  form .form-control{
    margin: 5px 0;
  }
  form .row{
    padding: 0 !important;
  }

  .slogan-home {
    font-size: 2.5rem;
  }
  @media only screen and (min-width: 768px) and (max-width: 959px) {
    p{
        font-size: 12px;
    }
    .slogan-home {
      font-size: 2.5rem;
    }
   
}

@media only screen and (max-width: 767px) {
    td{
        font-size: 14px;
    }
    .slogan-home {
      font-size: 2.0rem;
    }
    
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  td{
        font-size: 9px;
    }
    .slogan-home {
      font-size: 1.7rem;
    }
    img.logo{
      height: 50px;
      width: auto;
      margin-top: 10px;
    }
    
}

@media only screen and (max-width: 479px) {
  td{
        font-size: 7px;
    }
    .slogan-home {
      font-size: 1.6rem;
    }
    img.logo{
      height: 50px;
      width: auto;
      margin-top: 10px;
    }
}
}


/* Efeito scroll */

#list-clients {
  --marquee-width: 80vw;
  --marquee-height: 20vh;
  /* --marquee-elements: 12; */ /* defined with JavaScript */
  --marquee-elements-displayed: 6;
  --marquee-element-width: calc(var(--marquee-width) / var(--marquee-elements-displayed));
  --marquee-animation-duration: calc(var(--marquee-elements) * 1.25s);
}

.marquee {
  width: var(--marquee-width);
  height: var(--marquee-height);
  color: #eee;
  overflow: hidden;
  position: relative;
}

.marquee-content {
  list-style: none;
  height: 100%;
  display: flex;
  animation: scrolling var(--marquee-animation-duration) linear infinite;
}
.marquee-content:hover {
  animation-play-state: paused;
}
@keyframes scrolling {
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-1 * var(--marquee-element-width) * var(--marquee-elements))); }
}
.marquee-content li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: var(--marquee-element-width);
  max-height: 100%;
  font-size: calc(var(--marquee-height)*3/4); /* 5rem; */
  white-space: nowrap;
}

.marquee-content li img {
  width: 100%;
}

@media (max-width: 600px) {
  #list-clients {
    --marquee-width: 100vw;
    --marquee-height: 16vh;
    --marquee-elements-displayed: 3;
    margin: 0 0.6rem;
    
  }
  .marquee:before, .marquee:after { width: 5rem; }
}